export const icelandicMonths = [
    "Janúar",
    "Febrúar",
    "Mars",
    "Apríl",
    "Maí",
    "Júní",
    "Júlí",
    "Ágúst",
    "September",
    "Október",
    "Nóvember",
    "Desember",
];

export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const icelandicDaysOfWeek = [
    "Sunnudagur",
    "Mánudagur",
    "Þriðjudagur",
    "Miðvikudagur",
    "Fimmtudagur",
    "Föstudagur",
    "Laugardagur",
];

export const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
