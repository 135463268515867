export interface PageParams {
    page: string;
}

export interface BoardPageParams extends PageParams {
    boardName: string;
}

export const boardPageParamDict: {
    page: keyof Pick<BoardPageParams, "page">;
    board: keyof Pick<BoardPageParams, "boardName">;
} = {
    page: "page",
    board: "boardName",
};

export interface InvestmentPageParams extends PageParams {
    investmentName: string;
}

export const investmentPageParamDict: {
    page: keyof Pick<InvestmentPageParams, "page">;
    investment: keyof Pick<InvestmentPageParams, "investmentName">;
} = {
    page: "page",
    investment: "investmentName",
};

export const boardRoutes = {
    boards: { root: "boards", nextMeeting: "next", allMeetings: "all-meetings", materials: "materials" },
};

export const investmentRoutes = {
    investments: { root: "investments", materials: "materials" },
};

export const commonRoutes = {
    login: { root: "login", params: { token: "token", errorMessage: "errMsg" } },
};
