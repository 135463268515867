import { IInvestment, IInvestmentDocuments } from "../models/Investments";

import { User } from "@ozio-alfa/common/src/models/User";
import { JWTPayload } from "@ozio-alfa/common/src/models/JWT";

import jwt from "jsonwebtoken";

const tokenStorageKey = "token";

export default class Service {
    protected isLoggedIn: boolean = false;
    private token: string | undefined = undefined;
    private onUnauthorizedError: () => void;

    constructor(onUnauth: () => void) {
        this.onUnauthorizedError = onUnauth;
    }

    public getUserIfLoggedIn: () => User | undefined = () => {
        let token: string | null | undefined = this.token || localStorage.getItem(tokenStorageKey);
        if (!token) {
            return undefined;
        } else {
            if (tokenIsExpired(token)) {
                // TODO: refresh token?
                return undefined;
            } else if (this.token !== token) {
                this.token = token;
            }
        }
        return this.parseToken(this.token);
    };

    public setTokenAndGetUser: (token: string) => User = (token) => {
        this.token = token;
        localStorage.setItem(tokenStorageKey, token);
        return this.parseToken(token);
    };

    private parseToken: (token: string) => User = (token) => {
        try {
            const payload: JWTPayload = jwt.decode(token) as JWTPayload;
            return { name: payload.name };
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    public prepareLogin = () => {
        return this.get<{ id: string }>("/prepareLogin");
    };

    public logOut: () => void = () => {
        localStorage.removeItem(tokenStorageKey);
        this.token = undefined;
    };

    private get<T>(url: string): Promise<T> {
        return fetch(`/api${url}`, {
            method: "GET",
            headers: {
                ContentType: "application/json",
                Authorization: `Bearer ${this.token}`,
                // TODO: TEST ONLY
                // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwNDEwODI1Njk5IiwibmFtZSI6IsOTbGkgw412YXJzc29uIiwiaW52ZXN0b3JJZCI6IjEiLCJleHAiOjE2MzA0MjU3MDh9.sOPXFT0HzzYnsh-w0SG68KMw2tzWcpCb0p3r14Du1xQ`,
            },
        }).then(async (res) => {
            if (res.status === 401) {
                this.onUnauthorizedError();
            } else if (res.status !== 200) {
                return res.json().then((body) => {
                    throw new Error(body?.message || res.statusText || "Something went wrong");
                });
            } else {
                return res.json();
            }
        });
    }

    public getInvestments: () => Promise<IInvestment[]> = () => {
        // TODO: Parse img src
        return this.get("/investor/funds");
    };

    public getInvestmentByName: (investmentName: string) => Promise<IInvestment> = async (investmentName) => {
        return this.get<IInvestment[]>(`/investor/funds?name=${investmentName}`).then((investmentArray) => {
            let investment = investmentArray[0];
            if (investment === undefined) throw new Error("Investment not found");
            return investment;
        });
    };

    public getInvestmentDocuments: (investmentId: number) => Promise<IInvestmentDocuments[]> = async (investmentId) => {
        return this.get(`/investor/funds/${investmentId}/documents`);
    };
}
function tokenIsExpired(token: string) {
    const payload = jwt.decode(token) as JWTPayload;
    return !payload?.exp || Date.now() > payload.exp * 1000;
}
