import * as React from "react";
import Header from "./Header";
import Banner, { BannerProps } from "./Banner";
import { User } from "../../models/User";

export interface ICommonPageElementsProps {
    onRenderDefaultHeader: BannerProps["onRenderDefaultHeader"];
    user: User | undefined;
    logOut: () => void;
}

export interface ICommonPageElementsState {}

const CommonPageElements: React.FunctionComponent<ICommonPageElementsProps> = ({
    children,
    onRenderDefaultHeader,
    user,
    logOut,
}) => {
    return (
        <>
            {user !== undefined && (
                <>
                    <Header user={user} logOut={logOut} />
                    <Banner onRenderDefaultHeader={onRenderDefaultHeader} />
                </>
            )}
            {children}
        </>
    );
};

export default CommonPageElements;
