import * as React from "react";
import { RouteProps, Redirect, Route } from "react-router-dom";

interface IAuthRouteProps extends RouteProps {
    isLoggedIn: boolean;
}

export default class AuthRoute extends React.Component<IAuthRouteProps, {}> {
    public render(): React.ReactElement<IAuthRouteProps> {
        return this.props.isLoggedIn ? <Route {...this.props} /> : <Redirect to="/login" />;
    }
}
