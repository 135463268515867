// Ársreikningar
// Árshlutaskýrslur
// Fréttabréf
// Innkallanir
// Annað

import React from "react";
import logo from "./logo.svg";

import { Router, Switch, Route, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";

import { AlfaTheme, CommonPageElements, AuthRoute, history } from "@ozio-alfa/common";
import Login from "@ozio-alfa/common/src/pages/Login";
import { User } from "@ozio-alfa/common/src/models/User";

import { commonRoutes, investmentPageParamDict, investmentRoutes } from "@ozio-alfa/common/src/routes/routes";

import Service from "./services/Service";

import Typography from "@material-ui/core/Typography";
import MyInvestments from "./Pages/MyInvestments";
import { IInvestment } from "./models/Investments";
import InvestmentPage from "./Pages/InvestmentPage";
import Snackbar from "@material-ui/core/Snackbar";

interface IAppState {
    user: User | undefined;
    selectedInvestment: IInvestment | undefined;
    error: string;
}

interface IAppProps {}

class App extends React.Component<IAppProps, IAppState> {
    private service: Service;

    constructor(props: IAppProps) {
        super(props);

        this.service = new Service(this.handleUnauthorizedRequest);

        this.state = {
            user: this.service.getUserIfLoggedIn(),
            selectedInvestment: undefined,
            error: "",
        };
    }

    private setSelectedInvestment = async (investment: IInvestment | string, shouldNavigate: boolean = false) => {
        let selectedInvestment: IInvestment =
            typeof investment === "string" ? await this.service.getInvestmentByName(investment) : investment;

        this.setState({ selectedInvestment }, () => {
            shouldNavigate && history.push(`/${investmentRoutes.investments.root}/${selectedInvestment.title}`);
        });
    };

    private setErrorToast = (errorMessage: string) => {
        this.setState({ error: errorMessage }, () => {
            setTimeout(this.clearErrorToast, 6000);
        });
    };

    private clearErrorToast = () => this.setState({ error: "" });

    private logIn = (token: string) => {
        let user = this.service.setTokenAndGetUser(token);
        this.setState({ user }, () => {
            history.push(`/`);
        });
    };

    private logOut = () => {
        this.service.logOut();
        this.setState({ user: undefined }, () => {
            history.push(`/${commonRoutes.login.root}`);
        });
    };

    private handleUnauthorizedRequest = () => {
        this.setState({ user: undefined }, () => {
            history.push(
                `/${commonRoutes.login.root}?${commonRoutes.login.params.errorMessage}=Your session has expired, please login again`
            );
        });
    };

    public render() {
        console.log("Render <App />");

        return (
            <Router history={history}>
                <ThemeProvider theme={AlfaTheme}>
                    <CommonPageElements
                        onRenderDefaultHeader={() => (
                            <>
                                <Typography variant="h1">Investor Portal</Typography>
                                {/* <Typography variant="subtitle1" style={{ lineHeight: 1.2 }}>
                                    Welcome to Alfa Framtak's investor portal
                                </Typography> */}
                            </>
                        )}
                        user={this.state.user}
                        logOut={this.logOut}
                    >
                        {this.state.error && (
                            <Snackbar
                                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                open={Boolean(this.state.error)}
                                autoHideDuration={6000}
                                onClose={this.clearErrorToast}
                                message={this.state.error}
                            />
                        )}
                        <Switch>
                            <Route path="/login">
                                {this.state.user !== undefined && <Redirect to="/" />}
                                <Login setToken={this.logIn} prepareLogin={this.service.prepareLogin} />
                            </Route>
                            <Route path="/sanity">
                                <div className="App">
                                    <header className="App-header">
                                        <img src={logo} className="App-logo" alt="logo" />
                                        <p>
                                            Edit <code>src/App.tsx</code> and save to reload.
                                        </p>
                                        <a
                                            className="App-link"
                                            href="https://reactjs.org"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Learn React
                                        </a>
                                    </header>
                                </div>
                            </Route>
                            <AuthRoute
                                isLoggedIn={this.state.user !== undefined}
                                // TODO: Create dict and stuff
                                path={`/${investmentRoutes.investments.root}/:${investmentPageParamDict.investment}/:${investmentPageParamDict.page}?`}
                            >
                                <InvestmentPage
                                    investment={this.state.selectedInvestment}
                                    setSelectedInvestment={this.setSelectedInvestment}
                                    getInvestmentDocuments={this.service.getInvestmentDocuments}
                                    setErrorToast={this.setErrorToast}
                                />
                            </AuthRoute>
                            <AuthRoute isLoggedIn={this.state.user !== undefined} path="/">
                                <MyInvestments
                                    setSelectedInvestment={this.setSelectedInvestment}
                                    getInvestments={this.service.getInvestments}
                                />
                            </AuthRoute>
                        </Switch>
                    </CommonPageElements>
                </ThemeProvider>
            </Router>
        );
    }
}

export default App;
