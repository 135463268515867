import { Theme } from "@material-ui/core/styles";

export const wrapperClass = {
    wrapperClass: {
        maxWidth: 1240,
        margin: "0 auto",
        padding: "0px 16px",
    },
};

export const spinnerContainer = (theme: Theme) => ({
    spinnerContainer: {
        width: "fit-content",
        paddingTop: theme.spacing(5),
    },
});
