import React from "react";
import { Theme } from "@material-ui/core/styles/createTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/styles/withStyles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";

import { Link as RouterLink, LinkProps as RouterLinkProps, useParams } from "react-router-dom";

import { BoardPageParams } from "../routes/routes";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(1),
        },
    });

export interface PageMenuProps extends WithStyles<typeof styles> {
    // E.g. 'boards/umbreyting 1'
    rootPath: string;
    links: ListItemLinkProps[];
}

interface ListItemLinkProps {
    icon: React.ReactElement;
    text: string;
    to: string;
    isDirect?: boolean;
    isDefaultSelected?: boolean;
}

function PageMenu({ classes, rootPath, links }: PageMenuProps) {
    let { page } = useParams<BoardPageParams>();

    function ListLink(props: ListItemLinkProps) {
        const { icon, text, to, isDirect, isDefaultSelected } = props;

        const renderLink = React.useMemo(
            () =>
                React.forwardRef<any, Omit<RouterLinkProps, "to">>((itemProps, ref) => (
                    <RouterLink
                        to={isDirect ? to : `/${rootPath}/${to}`}
                        ref={ref}
                        {...itemProps}
                    />
                )),
            [to]
        );

        return (
            <li>
                <ListItem
                    selected={(isDefaultSelected && !page) || page == to}
                    button
                    component={renderLink}
                >
                    {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                    <ListItemText primary={text} />
                </ListItem>
            </li>
        );
    }

    return (
        <div className={classes.root}>
            <Paper elevation={0}>
                <List aria-label="company board pages">
                    {links.map((link) => (
                        <ListLink key={link.to} {...link} />
                    ))}
                    {/* <ListLink to="/" text="Mínar stjórnir" icon={<HomeIcon />} isDirect />
                    <ListLink to="next" text="Næsti stjórnarfundur" icon={<NextMeetingIcon />} isDefaultSelected />
                    <ListLink to="all-meetings" text="Allir fundir" icon={<AllMeetingsIcon />} />
                    // <BoardListLink to="agenda" primary="Starfsáætlun" icon={<AgendaIcon />} />
                    <ListLink to="materials" text="Almenn gögn" icon={<MaterialsIcon />} /> */}
                </List>
            </Paper>
        </div>
    );
}

export default withStyles(styles)(PageMenu);
