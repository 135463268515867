import { useCallback, useState } from "react";
import { useEffect } from "react";

import { Switch, useParams, useRouteMatch, Route } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createTheme";
import withStyles, { WithStyles } from "@material-ui/styles/withStyles";

import HomeIcon from "@material-ui/icons/Home";

import { wrapperClass } from "@ozio-alfa/common/src/utils/Styles";
import { history, Spinner } from "@ozio-alfa/common";
import { InvestmentPageParams, investmentRoutes } from "@ozio-alfa/common/src/routes/routes";
import PageMenu from "@ozio-alfa/common/src/components/PageMenu";
import { IInvestment, IInvestmentDocuments } from "../models/Investments";
import InvestmentDocuments from "../Sections/InvestmentDocuments";

const styles = (theme: Theme) =>
    createStyles({
        ...wrapperClass,
    });

export interface BoardPageProps extends WithStyles<typeof styles> {
    investment: IInvestment | undefined;
    setSelectedInvestment: (board: IInvestment | string) => Promise<void>;
    getInvestmentDocuments: (investmentId: IInvestment["id"]) => Promise<IInvestmentDocuments[]>;
    setErrorToast: (errorMessage: string) => void;
}

function InvestmentPage({
    classes,
    investment,
    setSelectedInvestment,
    getInvestmentDocuments,
    setErrorToast,
}: BoardPageProps) {
    // const { status, value: investments, error } = useAsync<IInvestment[], string | Error>(getInvestments);

    let [isLoading, setIsLoading] = useState(!investment);
    // let [error, setError] = useState("");

    const wrappedGetInvDocs = useCallback(
        () => getInvestmentDocuments(investment?.id || 0),
        [investment, getInvestmentDocuments]
    );

    let { investmentName } = useParams<InvestmentPageParams>();

    let { path } = useRouteMatch();

    useEffect(() => {
        if (!investment) {
            setSelectedInvestment(investmentName)
                .then(() => {
                    setIsLoading(false);
                })
                .catch((error: string | Error) => {
                    console.error(typeof error === "string" ? error : error.message);
                    setErrorToast(`There was an error finding: '${investmentName}'`);
                    setIsLoading(false);
                    history.push("/");
                });
        }
    }, [investment, setSelectedInvestment, setIsLoading, investmentName, setErrorToast]);

    console.log("Render <InvestmentPage />");

    return (
        <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            classes={{ root: `${classes.wrapperClass}` }}
        >
            {(isLoading || !investmentName) && (
                <>
                    {isLoading && <Spinner />}
                    {!isLoading && !investmentName && (
                        <Container>
                            {/* TODO: Handle errors */}
                            <span>404: Investment not found</span>
                        </Container>
                    )}
                </>
            )}
            {!isLoading && investment && (
                <>
                    <PageMenu
                        rootPath={`${investmentRoutes.investments.root}/${investmentName}`}
                        links={[
                            {
                                to: "/",
                                text: "My funds",
                                icon: <HomeIcon />,
                                isDirect: true,
                            },
                        ]}
                    />
                    <Switch>
                        <Route path={[path, `${path}/${investmentRoutes.investments.materials}`]}>
                            <InvestmentDocuments getInvestmentDocuments={wrappedGetInvDocs} />
                        </Route>
                    </Switch>
                </>
            )}
        </Grid>
    );
}

export default withStyles(styles)(InvestmentPage);
