import { createBrowserHistory } from "history";
import { useLocation } from "react-router-dom";
import { daysOfWeek, months } from "./DateConstants";

export function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export let history = createBrowserHistory();

export const getIcelandicTime = (date: Date) =>
    date.toLocaleTimeString("is-IS", { hour12: false, hour: "2-digit", minute: "2-digit" });

export const getIcelandicDate = (date: Date) =>
    `${daysOfWeek[date.getDay()]}, ${date.getDate()}. ${months[date.getMonth()]}`;

export const downloadFromUrl = (uri: string, fileName: string) => {
    let link = document.createElement("a");
    link.download = fileName;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
