import * as React from "react";

import {
    Grid,
    Theme,
    createStyles,
    WithStyles,
    withStyles,
    Hidden,
    Card,
    CardContent,
    Typography,
    alpha,
} from "@material-ui/core";

import image from "../../assets/harpa.jpg";
import { wrapperClass } from "../../utils/Styles";
import { months, daysOfWeek } from "../../utils/DateConstants";
import { Route, useParams, Switch } from "react-router-dom";
import { boardRoutes, investmentRoutes } from "../../routes/routes";

const styles = (theme: Theme) =>
    createStyles({
        banner: {
            // paddingTop: spacing(2),
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            willChange: "transform",
            backgroundPosition: "50% 63%",
            transform: "translate3d(0px, 0px, 0px)",
            // [theme.breakpoints.down("sm")]: {
            //     height: "150px",
            // },
            [theme.breakpoints.up("md")]: {
                height: "200px",
            },
        },
        container: {
            height: "100%",
        },
        welcome: {
            paddingTop: theme.spacing(4),
            [theme.breakpoints.up("md")]: {
                height: "80%",
            },
            [theme.breakpoints.down("md")]: {
                paddingTop: theme.spacing(1),
            },
            backgroundColor: alpha(theme.palette.primary.main, 0.75),
            color: "white",
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(5),
        },
        date: {
            height: "100%",
            width: "fit-content",
            backgroundColor: "white",
            padding: `0 ${theme.spacing(4)}px`,
            borderTop: `2px solid rgba(232, 232, 232, 0.75)`,
            borderBottom: `2px solid rgba(232, 232, 232, 0.75)`,
            // borderBottom: `2px solid ${darken(theme.palette.secondary.main, 0.05)}`,
            color: theme.palette.secondary.main,
            margin: 0,
        },
        ...wrapperClass,
    });

export interface BannerProps extends WithStyles<typeof styles> {
    onRenderDefaultHeader: () => JSX.Element;
}

export interface BannerState {
    today: Date;
}

class Banner extends React.Component<BannerProps, BannerState> {
    constructor(props: BannerProps) {
        super(props);
        this.state = { today: new Date() };
    }

    public render(): React.ReactElement<BannerProps> {
        return (
            <div className={this.props.classes.banner}>
                <div
                    className={`${this.props.classes.container} ${this.props.classes.wrapperClass}`}
                >
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        classes={{ root: `${this.props.classes.container}` }}
                    >
                        <Grid item className={this.props.classes.welcome}>
                            <Card
                                style={{
                                    backgroundColor: "unset",
                                    border: "inherit",
                                    color: "inherit",
                                }}
                                elevation={0}
                            >
                                <CardContent>
                                    <Switch>
                                        <Route
                                            path={[
                                                `/${boardRoutes.boards.root}/:entityName`,
                                                `/${investmentRoutes.investments.root}/:entityName`,
                                            ]}
                                        >
                                            <EntityHeader />
                                        </Route>
                                        <Route path="/">{this.props.onRenderDefaultHeader()}</Route>
                                    </Switch>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Hidden xsDown>
                            <Grid
                                item
                                container
                                className={this.props.classes.date}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >
                                {/* TODO: Skoða að setja inn klukku - make-ar sense fyrir fundi */}
                                <Grid item>{daysOfWeek[this.state.today.getDay()]}</Grid>
                                <Grid item>
                                    <Typography variant="h3" component="span">
                                        {this.state.today.getDate()}
                                    </Typography>
                                </Grid>
                                <Grid item>{`${
                                    months[this.state.today.getMonth()]
                                }, ${this.state.today.getFullYear()}`}</Grid>
                            </Grid>
                        </Hidden>
                    </Grid>
                </div>
            </div>
        );
    }
}

const EntityHeader: React.FunctionComponent<{}> = () => {
    let { entityName } = useParams<{ entityName: string }>();
    return <Typography variant="h1">{entityName}</Typography>;
};

export default withStyles(styles)(Banner);
