import { Theme } from "@material-ui/core/styles/createTheme";
import withStyles, { WithStyles } from "@material-ui/styles/withStyles";
import { createStyles } from "@material-ui/core/styles";

import { Spinner, EnhancedTable } from "@ozio-alfa/common";
import { useAsync } from "@ozio-alfa/common/src/utils/UseAsync";
import { getFileTypeIconProps, initializeFileTypeIcons } from "@fluentui/react-file-type-icons";
import { IInvestmentDocuments } from "../models/Investments";
import { downloadFromUrl } from "@ozio-alfa/common/src/utils/utils";
import Message from "@ozio-alfa/common/src/components/Message";
// import { Icon } from "@material-ui/core";
import { Icon } from "@fluentui/react/lib/Icon";
import { useEffect } from "react";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: `${theme.spacing(4)}px auto`,
            marginLeft: theme.spacing(3),
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            [theme.breakpoints.down("md")]: {
                width: "100%",
                margin: 0,
            },
        },
    });

export interface InvestmentDocumentsProps extends WithStyles<typeof styles> {
    getInvestmentDocuments: () => Promise<IInvestmentDocuments[]>;
}

function InvestmentDocuments({ classes, getInvestmentDocuments }: InvestmentDocumentsProps) {
    const { status, value: documents, error } = useAsync(getInvestmentDocuments);
    useEffect(() => {
        initializeFileTypeIcons(undefined);
    }, []);
    console.log("Render <InvestmentDocuments />");

    return (
        <div className={classes.root}>
            {status === "pending" && <Spinner />}
            {status === "success" && documents && documents.length !== 0 && (
                <EnhancedTable
                    downloadlink={true}
                    title={"Documents"}
                    items={documents}
                    columns={[
                        {
                            id: "docIcon",
                            label: "",
                            onRender: (itm) => (
                                <Icon
                                    {...getFileTypeIconProps({
                                        extension: itm.docIcon,
                                        size: 16,
                                        imageFileType: "png",
                                    })}
                                />
                            ),
                            disablePadding: true,
                        },
                        { id: "name", disablePadding: false, label: "Name" },
                        // TODO: Discuss columns
                        // { id: "uniqueId", label: "ID" }
                    ]}
                    idCol={"uniqueId"}
                    groupByKey={"type"}
                    onClick={(ev, item) => {
                        downloadFromUrl(item.downloadUrl, item.name);
                    }}
                    disableSort={true}
                />
            )}
            {status === "success" && (!documents || (documents && documents.length === 0)) && (
                <Message message="No documents were found for this investment" />
            )}
            {status === "error" && (
                <Message
                    message="There was an error fetching your investment documents"
                    internalError={(error !== null && error) || undefined}
                />
            )}
        </div>
    );
}

export default withStyles(styles)(InvestmentDocuments);
