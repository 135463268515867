import { Theme } from "@material-ui/core/styles/createTheme";
import withStyles, { WithStyles } from "@material-ui/styles/withStyles";
import { createStyles, alpha } from "@material-ui/core/styles";

import { useCallback } from "react";
import { Card, CardActions, CardContent, CardMedia, Hidden, Typography, Button, MenuItem, TextField, FormControl, InputLabel, Select, InputBase, Input, CircularProgress, Box } from "@material-ui/core";

import logo from "../assets/Alfa_black.png";
import loginPageImage from "../assets/fjarfestar_login_alfa.jpg";

import { history, useQuery } from "../utils/utils";
import { useEffect } from "react";
import { commonRoutes } from "../routes/routes";
import { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { generateHashString, calculateVcode } from "../utils/crypto";

const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
        root: {
            'label + &': {
                marginTop: theme.spacing(2),
            },
        },
        input: {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            fontSize: 16,
            padding: '10px 26px 10px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                borderRadius: 4,
                borderColor: '#80bdff',
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
        },
    }),
)(InputBase);
const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flex: "1 0 auto",
            width: "100%",
            minWidth: "100%",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
        },
        leftImage: {
            height: "100%",
            background: `linear-gradient(0deg, ${alpha(theme.palette.secondary.main, 0.25)}, ${alpha(
                theme.palette.secondary.main,
                0.25
            )}), url("${loginPageImage}")`,
            backgroundSize: "cover",
            backgroundPosition: "left",
            flexBasis: 0,
            flexGrow: 5,
        },
        cardContainer: {
            padding: theme.spacing(3),
            flexBasis: 0,
            flexGrow: 5,
        },
        card: {
            backgroundColor: "#fff",
            maxWidth: 500,
            padding: 32,
            margin: "auto",
            textAlign: "center",
            // boxShadow:
            //     "0 8px 10px -5px rgb(0 0 0 / 20%), 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%)",
        },
        media: {
            height: 70,
            objectFit: "contain",
            marginBottom: theme.spacing(4),
        },
        header: {
            paddingBottom: theme.spacing(3),
            [theme.breakpoints.down("md")]: {
                fontWeight: "800",
                font: "Arial",
            },
        },
        text: {
            maxWidth: 400,
            margin: "auto",
        },
        actions: {
            justifyContent: "center",
            paddingTop: theme.spacing(2),
        },
        link: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            border: `3px solid ${theme.palette.primary.main}`,
            padding: `${theme.spacing(2)}px ${theme.spacing(5)}px ${theme.spacing(1)}px`,
            cursor: "pointer",
            // background: "#f7f7f7",
            borderadius: 2,
            color: theme.palette.primary.main,
            fontWeight: 600,
            "&:hover": {
                textDecoration: "none",
            },
        },
        image: {
            maxHeight: 24,
        },
        linkText: {
            paddingTop: theme.spacing(1),
        },
        bottomText: {
            paddingTop: theme.spacing(6),
        },
    });

export interface LoginProps extends WithStyles<typeof styles> {
    setToken: (token: string) => void;
    prepareLogin: () => Promise<{ id: string }>;
}

function Login({ classes, setToken, prepareLogin: _prepLogin }: LoginProps) {
    const query = useQuery();
    const [error, setError] = useState<string | null>(query.get(commonRoutes.login.params.errorMessage));
    const [loginAuthId, setLoginAuthId] = useState<string | undefined>();

    const [pinHash, setPinHash] = useState<string | undefined>();
    const [loginHint, setLoginHint] = useState<string>('');
    const [selectedEidMethod, setSelectedEidMethod] = useState<string>("sim");

    const prepLogin = useCallback(() => {
        _prepLogin().then((r) => setLoginAuthId(r.id));
    }, [_prepLogin, setLoginAuthId]);

    useEffect(() => {
        const token = query.get(commonRoutes.login.params.token);

        if (token) {
            setToken(token);
        } else if (!loginAuthId) {
            prepLogin();
        }

        if (error && query.get(commonRoutes.login.params.errorMessage)) {
            history.push(`/${commonRoutes.login.root}`);
        }
    }, [query, setToken, error, loginAuthId, prepLogin]);

    console.log("Render <Login />");
    // TODO: Skoða bug á lágum skjám

    return (
        <div className={classes.root}>
            <Hidden mdDown>
                <div className={classes.leftImage}></div>
            </Hidden>
            {error && (
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={Boolean(error)}
                    autoHideDuration={8000}
                    onClose={() => {
                        setError(null);
                    }}
                    message={error}
                />
            )}
            <div className={classes.cardContainer}>
                <Card className={classes.card} square elevation={0}>
                    <CardContent>
                        <CardMedia className={classes.media} component="img" src={logo} title="Alfa Logo" />
                        <Typography variant="h2" className={classes.header}>
                            Investor Portal
                        </Typography>
                        <div className={classes.text}>
                            Welcome to the Alfa Framtak Investor Portal. Login to access your data.
                        </div>
                    </CardContent>
                    <CardActions className={classes.actions}>
                        <div style={{ width: 170 }}>
                            <form method="post" action={'/eid/login'}>
                                <FormControl fullWidth id="authType">
                                    <InputLabel htmlFor="authType-label">Tegund auðkenningu</InputLabel>
                                    <Select
                                        labelId="authType-label"
                                        name="authType"
                                        id="authType"
                                        defaultValue="sim"
                                        onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) => setSelectedEidMethod(e.target.value as string)}
                                        input={<BootstrapInput />}
                                    >
                                        {[
                                            { value: "sim", label: "Rafræn skilríki" },
                                            { value: "app", label: "Auðkennisapp" },
                                        ].map((option) => (
                                            <MenuItem key={option.value} value={option.value} >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <>
                                    <TextField
                                        id="pinHash"
                                        label="pinHash"
                                        variant="standard"
                                        name="pinHash"
                                        value={pinHash}
                                        hidden={true}
                                        style={{ display: "none" }}
                                    />
                                    <FormControl style={{ marginTop: "16px", marginBottom: "16px" }}>
                                        <InputLabel htmlFor="loginHint">{selectedEidMethod === "sim" ? "Símanúmer" : "Kennitala"}</InputLabel>
                                        <Input
                                            id="loginHint"
                                            name="loginHint"
                                            value={loginHint}
                                            onChange={(e) => setLoginHint(e.target.value)}
                                            fullWidth
                                            style={{ marginBottom: 4, paddingLeft: "12px" }} />
                                    </FormControl>
                                    <Button
                                        type="submit"
                                        style={{
                                            // ":hover": { boxShadow: "none" },
                                            boxShadow: "none",
                                            height: "62px",
                                            borderRadius: "16px",
                                        }}
                                        onClick={() => {
                                            setPinHash(generateHashString(loginHint));
                                        }}
                                        disabled={!loginAuthId}
                                        color="secondary"
                                        variant="contained"
                                        fullWidth
                                    >
                                        <Typography variant="body1" component={"span"}>
                                            Innskrá
                                        </Typography>
                                    </Button>
                                    {pinHash == undefined ?
                                        <></>
                                        :
                                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", marginTop: "8px" }}>
                                            <Typography variant="body1" component={"span"}>
                                                PIN: {calculateVcode(pinHash)}
                                            </Typography>
                                            <CircularProgress color="primary" />
                                        </Box>}
                                </>

                            </form>
                        </div>
                    </CardActions>
                    <CardContent className={classes.bottomText}>
                        <Hidden smDown>Alfa Framtak ehf. | Lágmúli 9, 108 Reykjavík | All rights reserved</Hidden>
                        <Hidden mdUp>
                            Alfa Framtak ehf. <br /> Lágmúli 9, 108 Reykjavík <br /> All rights reserved
                        </Hidden>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}

export default withStyles(styles)(Login);
