import React, { ReactText } from "react";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { useState } from "react";
import { Link as DLink, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { createStyles, lighten, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createTheme";
import { useMemo } from "react";
import get from "lodash.get";

import find from "lodash.find";
import Collapse from "@material-ui/core/Collapse";
import { IBasicEntity, IDocument } from "../models/Entities";

// export interface ImplicitEnhancedTableProps<T extends { ID: number }> {
//     items: T[];
//     columns: Column<T>[];
//     idCol: undefined;
// }

// export interface ExplicitEnhancedTableProps<T extends any> {
export interface EnhancedTableProps<T> {
    downloadlink?: boolean;
    items: T[];
    columns: Column<T>[];
    idCol?: keyof T;
    title: string;
    groupByKey?: keyof T;
    onClick: (event: React.MouseEvent<unknown>, item: T) => void;
    // groupByValues?: any[]; TODO: Maybe implement
    disableSort?: boolean;
}

// export type EnhancedTableProps<T extends any | { ID: number }> = T extends { ID: number } TODO: Get to work
//     ? ImplicitEnhancedTableProps<T>
//     : ExplicitEnhancedTableProps<T>;

interface Column<T> {
    id: Extract<keyof T, string>;
    label: string;
    numeric?: boolean;
    disablePadding?: boolean;
    onRender?: (item: T, key: Column<T>["id"]) => JSX.Element | React.ReactText;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = "asc" | "desc";

function getComparator<T, Key extends keyof T = keyof T>(
    order: Order,
    orderBy: Key
): (a: T, b: T) => number {
    return order === "desc"
        ? (a, b) => descendingComparator<T>(a, b, orderBy)
        : (a, b) => -descendingComparator<T>(a, b, orderBy);
}

function stableSort<T extends Object>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface TableHeaderProps<T extends Object | (Object & { ID: number })> {
    classes: ReturnType<typeof useStyles>;
    columns: Column<T>[];
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: Extract<keyof T, string>) => void;
    // onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: Extract<keyof T, string>;
    rowCount: number;
    disableSort?: boolean;
}

function EnhancedTableHead<T>(props: TableHeaderProps<T>) {
    const {
        classes,
        columns,
        // onSelectAllClick,
        order,
        orderBy,
        // numSelected,
        // rowCount,
        onRequestSort,
        disableSort = false,
    } = props;
    const createSortHandler =
        (property: Extract<keyof T, string>) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding="checkbox" key="checkbox"> */}
                {/* <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ "aria-label": "select all desserts" }}
                    /> */}
                {/* </TableCell> */}
                {columns.map((headCell, idx) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={idx === 0 ? { paddingLeft: 16 } : {}}
                    >
                        {!disableSort && (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        )}
                        {disableSort && headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === "light"
                ? {
                      color: theme.palette.secondary.main,
                      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                  }
                : {
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.secondary.dark,
                  },
        title: {
            flex: "1 1 100%",
        },
    })
);

interface EnhancedTableToolbarProps {
    numSelected: number;
    title: string;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected, title } = props;

    return (
        <Toolbar className={[classes.root, numSelected > 0 ? classes.highlight : ""].join(" ")}>
            {numSelected > 0 ? (
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    {title}
                </Typography>
            )}
            {
                numSelected > 0 && (
                    <Tooltip title="Delete">
                        <IconButton aria-label="delete">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                )
                // : (
                //     <Tooltip title="Filter list">
                //         <IconButton aria-label="filter list">
                //             <FilterListIcon />
                //         </IconButton>
                //     </Tooltip>
                // )
            }
        </Toolbar>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        paper: {
            width: "100%",
            marginBottom: theme.spacing(2),
        },
        table: {
            // [theme.breakpoints.down("md")]: {
            //     backgroundColor: theme.palette.primary.main,
            // },
        },
        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
    })
);

interface EnhancedTableRowsProps<T> {
    downloadlink?: boolean;
    items: T[];
    order: Order;
    orderBy: Extract<keyof T, string>;
    isSelected: (name: T[keyof T]) => boolean;
    idCol: keyof T;
    handleClick: (event: React.MouseEvent<unknown>, item: T) => void;
    columns: Column<T>[];
}

function EnhancedTableRows<T extends Object>({
    downloadlink,
    items,
    order,
    orderBy,
    isSelected,
    idCol,
    handleClick,
    columns,
}: EnhancedTableRowsProps<T>) {
    return (
        <>
            {/* TODO: Sort items Memo-ically? */}
            {stableSort<T>(items, getComparator<T>(order, orderBy)).map((itm, index) => {
                const isItemSelected = isSelected(itm[idCol]);
                const labelId = `enhanced-table-checkbox-${index}`;
                // console.log("itm, ", itm);
                const ITM = itm as unknown as IBasicEntity;
                const ITM2 = itm as unknown as IDocument;
                // const ITM2 = itm  as IBasicEntity;
                // console.log(ITM.title);
                // console.log(ITM2.title);
                var corrurl = "";
                if (itm.hasOwnProperty("title")) {
                    corrurl = `/investments/${ITM.title}`;
                } else {
                    corrurl = ITM2.downloadUrl ? ITM2.downloadUrl : "";
                }

                return (
                    <TableRow
                        // component={(props) => {
                        //     if (itm.hasOwnProperty("title")) {
                        //         return <Link {...props} to={`/investments/${ITM.title}`} />;
                        //     }
                        //     return <DLink {...props} href={ITM2.downloadUrl} />;
                        // }}
                        // underline="none"
                        // hover
                        onClick={(event: React.MouseEvent<unknown>) => handleClick(event, itm)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        // Todo: Type-a betur!
                        key={itm[idCol] as any as string}
                        selected={isItemSelected}
                        style={{ cursor: "pointer" }}
                    >
                        {columns.map((col, idx) => {
                            const onRender: Column<T>["onRender"] =
                                col.onRender ??
                                (((itm: T, key: string) => get(itm, key)) as any as (
                                    itm: T,
                                    key: string
                                ) => ReactText);

                            return (
                                <TableCell
                                    {...(idx === 0 && downloadlink
                                        ? {
                                              id: labelId,
                                              scope: "row",
                                              width: "20px",
                                          }
                                        : idx === 0
                                        ? { id: labelId, scope: "row", width: "15%" }
                                        : {})}
                                    key={col.id}
                                    style={{
                                        // TODO: set padding on first with proper styles and theme
                                        ...(idx === 0 ? { paddingLeft: 16 } : {}),
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        height: "53px",
                                    }}
                                >
                                    {downloadlink ? (
                                        <DLink
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                            href={corrurl}
                                            underline="none"
                                            color="inherit"
                                        >
                                            {onRender(itm, col.id)}
                                        </DLink>
                                    ) : (
                                        <Link
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                            to={corrurl}
                                        >
                                            {onRender(itm, col.id)}
                                        </Link>
                                    )}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                );
            })}
        </>
    );
}
// const CorrectLink = (url: string, downloadlink?: boolean,{children}): any => {
//     if (downloadlink) {
//         return <DLink href={url} />;
//     } else {
//         return <Link to={url} />;
//     }
// };

function EnhancedTable<T extends Object | (Object & { ID: number })>(props: EnhancedTableProps<T>) {
    const classes = useStyles();
    const {
        downloadlink,
        columns,
        items,
        idCol: explicitID,
        title,
        groupByKey,
        onClick,
        disableSort = false,
    } = props;
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState<Extract<keyof T, string>>(
        columns[0].id as Extract<keyof T, string>
    );
    const [openGroups, setOpenGroups] = useState<string[]>([]);
    const [selected, setSelected] = useState<any[]>([]);
    const idCol: keyof T = useMemo(() => {
        if (explicitID) {
            return explicitID;
        }
        if (items[0]?.hasOwnProperty("ID")) {
            return "ID" as keyof T;
        } else throw new Error("No ID given for <EnhancedTable/>");
    }, [items, explicitID]);

    const groups: { Title: string; items: T[] }[] | undefined = useMemo(() => {
        if (!groupByKey) return undefined;
        return items
            .reduce<{ Title: string; items: T[] }[]>((groups, curr, idx) => {
                // TODO: Improve typing
                let currGroupTitle: string =
                    (curr[groupByKey] as any as { title: string })?.title || "Other";

                let group: { Title: string; items: T[] } | undefined = find(
                    groups,
                    (grp) => grp.Title === currGroupTitle
                );

                if (!group) {
                    group = { Title: currGroupTitle, items: [] };
                    groups.push(group);
                }

                group.items.push(curr);
                return groups;
            }, [])
            .sort((a, b) =>
                a.Title === "Other" ? 1 : b.Title === "Other" ? -1 : a.Title.localeCompare(b.Title)
            );
    }, [items, groupByKey]);

    const openGroup = (groupName: string) => {
        let newOpenGroups = openGroups.concat(groupName);
        setOpenGroups(newOpenGroups);
    };

    const closeGroup = (groupToCloseName: string) => {
        setOpenGroups(openGroups.filter((groupName) => groupName !== groupToCloseName));
    };

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: Extract<keyof T, string>
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    // const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     if (event.target.checked) {
    //         //@ts-ignore
    //         const newSelecteds = items.map((n) => n[idCol]);
    //         setSelected(newSelecteds);
    //         return;
    //     }
    //     setSelected([]);
    // };

    // const handleClick = (event: React.MouseEvent<unknown>, name: T[keyof T]) => {
    //     const selectedIndex = selected.indexOf(name);
    //     let newSelected: any[] = [];

    //     if (selectedIndex === -1) {
    //         newSelected = newSelected.concat(selected, name);
    //     } else if (selectedIndex === 0) {
    //         newSelected = newSelected.concat(selected.slice(1));
    //     } else if (selectedIndex === selected.length - 1) {
    //         newSelected = newSelected.concat(selected.slice(0, -1));
    //     } else if (selectedIndex > 0) {
    //         newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    //     }

    //     setSelected(newSelected);
    // };

    const isSelected = (name: T[keyof T]) => selected.indexOf(name) !== -1;

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar title={title} numSelected={selected.length} />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            columns={columns}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            // onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={items.length}
                            disableSort={disableSort}
                        />
                        <TableBody>
                            {groups &&
                                groups.map((grp) => {
                                    let isOpen = openGroups.indexOf(grp.Title) !== -1;
                                    return (
                                        <React.Fragment key={grp.Title}>
                                            <TableRow
                                                hover
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                    isOpen
                                                        ? closeGroup(grp.Title)
                                                        : openGroup(grp.Title)
                                                }
                                                key={`${grp.Title}-header`}
                                            >
                                                <TableCell padding="checkbox" key="expander">
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        style={{ padding: 9 }}
                                                    >
                                                        {isOpen ? (
                                                            <KeyboardArrowUpIcon />
                                                        ) : (
                                                            <KeyboardArrowDownIcon />
                                                        )}
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell
                                                    colSpan={columns.length}
                                                    style={{ fontWeight: 600 }}
                                                    key="title"
                                                >
                                                    {grp.Title} ({grp.items.length})
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key={`${grp.Title}-content`}>
                                                <TableCell
                                                    style={{ padding: 0 }}
                                                    colSpan={columns.length + 1}
                                                >
                                                    <Collapse
                                                        in={isOpen}
                                                        timeout="auto"
                                                        unmountOnExit
                                                    >
                                                        {/* TODO: Style with actual colors and theme */}
                                                        <Table
                                                            style={{
                                                                backgroundColor:
                                                                    "rgba(126,135,140,0.15)",
                                                            }}
                                                        >
                                                            <TableBody>
                                                                <EnhancedTableRows
                                                                    downloadlink={downloadlink}
                                                                    items={grp.items}
                                                                    order={order}
                                                                    orderBy={orderBy}
                                                                    isSelected={isSelected}
                                                                    idCol={idCol}
                                                                    handleClick={onClick}
                                                                    columns={columns}
                                                                />
                                                            </TableBody>
                                                        </Table>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })}
                            {!groups && (
                                <EnhancedTableRows
                                    items={items}
                                    order={order}
                                    orderBy={orderBy}
                                    isSelected={isSelected}
                                    idCol={idCol}
                                    handleClick={onClick}
                                    columns={columns}
                                />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}

export default EnhancedTable;
