import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@material-ui/core";
import Container from "@material-ui/core/Box";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createTheme";
import withStyles, { WithStyles } from "@material-ui/styles/withStyles";

import { IBasicEntity } from "../models/Entities";

const styles = (theme: Theme) =>
    createStyles({
        root: {},
        image: {
            height: 80,
            padding: `${theme.spacing(4)}px ${theme.spacing(0)}px`,
            objectFit: "contain",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        },
    });

export interface EntityCardProps<T extends IBasicEntity> extends WithStyles<typeof styles> {
    board: T;
    onClick: (board: T, shouldNavigate?: boolean) => void;
}

function UnwrappedEntityCard<T extends IBasicEntity>({ classes, board, onClick }: EntityCardProps<T>) {
    return (
        <Card className={classes.root} raised={true} elevation={4} square>
            <CardActionArea
                onClick={() => {
                    onClick(board, true);
                }}
            >
                <Container className={classes.image}>
                    <CardMedia
                        component="img"
                        src={board.imageUrl}
                        // className={classes.image}
                        title={board.title}
                    ></CardMedia>
                </Container>
                {/* TODO: Endurskoða hvort Alfa vilji texta */}
                {/* <CardContent>
                    <Typography variant="subtitle1">{board.Title}</Typography>
                </CardContent> */}
            </CardActionArea>
        </Card>
    );
}

export const EntityCard = withStyles(styles)(UnwrappedEntityCard);
