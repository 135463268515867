import { Grid, Typography } from "@material-ui/core";

import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createTheme";
import withStyles, { WithStyles } from "@material-ui/styles/withStyles";
import { EntityCardProps } from "@ozio-alfa/common/src/components/EntityCard";
import { wrapperClass } from "@ozio-alfa/common/src/utils/Styles";
import Message from "@ozio-alfa/common/src/components/Message";

import { IInvestment } from "../models/Investments";
import { EnhancedTable, Spinner } from "@ozio-alfa/common";
import { useAsync } from "@ozio-alfa/common/src/utils/UseAsync";
import Container from "@material-ui/core/Container";

const styles = (theme: Theme) =>
    createStyles({
        wrapperClass: {
            ...wrapperClass.wrapperClass,
            paddingTop: theme.spacing(2),
        },
        message: {
            width: "fit-content",
            margin: "auto",
            padding: theme.spacing(3),
            paddingBottom: 0,
        },
    });

export interface MyInvestmentsProps extends WithStyles<typeof styles> {
    getInvestments: () => Promise<IInvestment[]>;
    setSelectedInvestment: EntityCardProps<IInvestment>["onClick"];
}

function MyInvestments({ classes, getInvestments, setSelectedInvestment }: MyInvestmentsProps) {
    const { status, value: investments, error } = useAsync<IInvestment[], string | Error>(getInvestments);

    return (
        <div className={`${classes.wrapperClass}`}>
            <Grid container justifyContent="flex-start" alignItems="center" wrap="wrap" spacing={3}>
                {status === "pending" && <Spinner />}
                {status === "success" && investments && investments.length > 0 && (
                    <EnhancedTable
                        title={"Investments"}
                        items={investments}
                        columns={[
                            { id: "title", disablePadding: false, label: "Name" },
                            { id: "established", disablePadding: false, label: "Established" },
                            // TODO: Discuss columns
                            // { id: "uniqueId", label: "ID" }
                        ]}
                        idCol={"id"}
                        // groupByKey={"type"}
                        onClick={(ev, item) => {
                            // downloadFromUrl(item.downloadUrl, item.name);
                            setSelectedInvestment(item, false);
                        }}
                        disableSort={true}
                    />
                    // <></>
                )}
                {status === "success" && investments && investments.length === 0 && (
                    <Container>
                        <Typography component="div" variant="h5" className={classes.message}>
                            We found no investments for you
                        </Typography>
                    </Container>
                )}
                {status === "error" && (
                    <Message
                        message="There was an error fetching your investments"
                        internalError={(error !== null && error) || undefined}
                    />
                )}
            </Grid>
        </div>
    );
}

export default withStyles(styles)(MyInvestments);
