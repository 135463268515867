import * as React from "react";

import {
    Grid,
    // Typography,
    Theme,
    createStyles,
    WithStyles,
    withStyles,
    Button,
    Menu,
    MenuItem,
} from "@material-ui/core";

import DownArrowIcon from "@material-ui/icons/KeyboardArrowDown";
// import SearchIcon from "@material-ui/icons/Search";

import logo from "../../assets/Alfa_black.png";
import { wrapperClass } from "../../utils/Styles";
import { Link } from "react-router-dom";
import { User } from "../../models/User";

const styles = ({ palette, spacing, breakpoints, typography }: Theme) =>
    createStyles({
        header: {
            paddingTop: spacing(0.5),
            paddingBottom: spacing(0.5),
        },
        link: {
            lineHeight: "2.5rem",
        },
        logo: {
            display: "inline-block",
            verticalAlign: "middle",
            maxHeight: 35,
        },
        actions: {
            width: "fit-content",
        },
        accountButton: {
            fontSize: "1rem",
        },
        downArrowIcon: {
            marginLeft: "0px",
            "& > svg:first-child": {
                fontSize: "2rem",
            },
        },
        searchIcon: {
            paddingTop: "6px",
        },
        translate: {
            border: "3px solid",
            borderColor: palette.primary.main,
            display: "block",
            width: "22px",
            // height: "26px",
            lineHeight: "20px",
            textAlign: "center",
            color: palette.primary.main,
            fontSize: 14,
            fontWeight: typography.fontWeightBold,
        },
        ...wrapperClass,
    });

export interface IHeaderProps extends WithStyles<typeof styles> {
    user: User | undefined;
    logOut: () => void;
}

export interface IHeaderState {
    anchorElement: HTMLElement | undefined;
}

class HeaderComponent extends React.Component<IHeaderProps, IHeaderState> {
    constructor(props: IHeaderProps) {
        super(props);

        this.state = {
            anchorElement: undefined,
        };
    }

    public render(): React.ReactElement<IHeaderProps> {
        // TODO: Endurskoða mögulega sérstaklega m.t.t. minni skjáa
        let userName = this.props.user?.name || "";
        if (userName.length > 15) {
            let names = userName.split(" ");
            if (names[0].length < 15) {
                userName = names[0];
            } else if (names[names.length - 1].length < 15) {
                userName = names[names.length - 1];
            } else {
                userName = "User";
            }
        }

        return (
            <header className={this.props.classes.header}>
                <div className={this.props.classes.wrapperClass}>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item xs={4}>
                            <Link to="/" className={this.props.classes.link}>
                                <img
                                    src={logo}
                                    className={this.props.classes.logo}
                                    alt="Alfa Framtak"
                                />
                            </Link>
                        </Grid>
                        <Grid
                            xs={8}
                            item
                            container
                            spacing={2}
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            classes={{ root: this.props.classes.actions }}
                        >
                            <Grid item>
                                {/* TODO: Render as icon on small screen */}
                                <Button
                                    onClick={(ev) => {
                                        ev.persist();
                                        this.setState({ anchorElement: ev.currentTarget });
                                    }}
                                    endIcon={<DownArrowIcon color="primary" fontSize="inherit" />}
                                    size="large"
                                    classes={{
                                        label: this.props.classes.accountButton,
                                        iconSizeLarge: this.props.classes.downArrowIcon,
                                    }}
                                >
                                    {userName}
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={this.state.anchorElement}
                                    getContentAnchorEl={null}
                                    // keepMounted
                                    open={Boolean(this.state.anchorElement)}
                                    onClose={this.handleMenuClose}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                >
                                    {/* <MenuItem onClick={this.handleMenuClose}>Profile</MenuItem>
                                <MenuItem onClick={this.handleMenuClose}>My account</MenuItem> */}
                                    <MenuItem onClick={this.props.logOut}>Logout</MenuItem>
                                </Menu>
                            </Grid>
                            {/* <Grid item>
                            <SearchIcon className={this.props.classes.searchIcon} color="primary" />
                        </Grid> */}
                            {/* <Grid item>
                            <Typography className={this.props.classes.translate}>EN</Typography>
                        </Grid> */}
                        </Grid>
                    </Grid>
                </div>
            </header>
        );
    }

    private handleMenuClose = () => {
        this.setState({ anchorElement: undefined });
    };
}

export default withStyles(styles)(HeaderComponent);
