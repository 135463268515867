import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

let headingFont = "'Arial Black', 'Arial Bold', Gadget, sans-serif";

export const AlfaTheme = responsiveFontSizes(
    createTheme({
        spacing: 8,
        palette: {
            primary: {
                main: "rgb(212, 38, 38)",
            },
            secondary: {
                main: "rgb(12, 9, 10)",
            },
            error: {
                main: "rgb(126, 135, 140)",
            },
        },
        overrides: {
            MuiDialogActions: {
                root: {
                    justifyContent: "space-around",
                },
            },
            MuiSelect: {
                root: {
                    textAlign: "start",
                },
                select: {
                    "&:focus": {
                        "background-color": undefined,
                    },
                },
            },
            MuiExpansionPanelSummary: {
                content: {
                    "&$expanded": {
                        margin: undefined,
                    },
                },
            },
            MuiStepper: {
                root: {
                    maxWidth: "1200px",
                    margin: "auto",
                },
            },
            MuiFormLabel: {
                root: {
                    textAlign: "start",
                    paddingBottom: "25px",
                    fontWeight: "bold",
                    color: "#000000",
                },
                asterisk: {
                    color: "#FF0050",
                },
            },
            // MuiCard: {
            //     root: {
            //         // border: "1px solid #E6E6EC",
            //     },
            // },
            MuiCardContent: {
                root: {
                    padding: "15px",
                },
            },
            MuiInputBase: {
                root: {
                    height: "48px",
                    border: "solid 1px #CCCCD8",
                    borderRadius: "5px",
                },
                multiline: {
                    height: "inherit",
                },
            },
            MuiButton: {
                root: {
                    textTransform: "none",
                    paddingLeft: "48px",
                    paddingRight: "48px",
                    paddingTop: "12px",
                    paddingBottom: "12px",
                },
            },
            MuiExpansionPanel: {
                root: {
                    "&$expanded": {
                        margin: undefined,
                    },
                },
            },
            MuiIconButton: {
                root: {
                    padding: "5px",
                },
            },
            MuiTypography: {},
        },
        typography: {
            fontFamily: "Arial, Helvetica, sans-serif",
            h1: {
                fontFamily: headingFont,
                fontSize: "2.25rem",
            },
            h2: {
                fontFamily: headingFont,
                fontSize: "2.3rem",
            },
            h3: {
                fontFamily: headingFont,
                fontSize: "2rem",
            },
            h4: {
                fontFamily: headingFont,
            },
            h5: {
                fontFamily: headingFont,
            },
            h6: {
                fontFamily: headingFont,
            },
        },
    })
);
