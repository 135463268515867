import { CircularProgress, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createTheme";
import withStyles, { WithStyles } from "@material-ui/styles/withStyles";
import { spinnerContainer } from "../utils/Styles";

// const styles = (theme: Theme) =>
//     createStyles({
//         ...spinnerContainer(theme),
//     });

interface SpinnerProps {}

const useStyles = makeStyles((theme) => ({
    ...spinnerContainer(theme),
}));

function Spinner() {
    const classes = useStyles();
    return (
        <Container classes={{ root: classes.spinnerContainer }}>
            <CircularProgress size={70} />
        </Container>
    );
}

export default Spinner;
