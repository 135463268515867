import Container from "@material-ui/core/Container";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createTheme";
import Typography from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/styles/withStyles";

const styles = (theme: Theme) =>
    createStyles({
        message: {
            width: "fit-content",
            margin: "auto",
            padding: theme.spacing(3),
            paddingBottom: 0,
        },
        subMessage: {
            width: "fit-content",
            margin: "auto",
            padding: theme.spacing(1),
            paddingBottom: 0,
        },
    });

export interface MessageProps extends WithStyles<typeof styles> {
    message: string;
    internalError?: string | Error;
}

function Message({ classes, message, internalError }: MessageProps) {
    return (
        <div>
            <Container>
                <Typography component="div" variant="h5" className={classes.message}>
                    {message}
                </Typography>
                {internalError && (
                    <Typography component="div" variant="subtitle1" className={classes.subMessage}>
                        Internal Error: {typeof internalError === "string" ? internalError : internalError.message}
                    </Typography>
                )}
            </Container>
        </div>
    );
}

export default withStyles(styles)(Message);
